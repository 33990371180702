import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { Alert, Loading } from 'components';
import { Button } from 'components/shared';
import RidProfileForm from 'components/onboarding/RidProfile';
import { RidProfile, Events, notifySuccess, notifyError } from 'api';
import { EventDictionary } from 'helpers/dictionaries';

const { PUB_STATUS } = EventDictionary;

const SubmitButton = (props) => (
  <div className="center">
    <Button
      className="btn btn-lg btn-success"
      icon="fa-pencil-square-o fa-lg"
      text="Sign Activity Report Form"
      onClick={props.onClick}
    />
  </div>
);

SubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

class CompleteSurveyPage extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
  };

  state = {
    signFormOpen: false,
    loading: true,
    grade: null,
  };

  handleSignActivityReportForm = async () => {
    const { eventId } = this.props.match.params;
    let { grade } = this.state;
    try {
      grade = await Events.patchGrade(eventId, grade.pk, {
        signed: true,
      });
    } catch (err) {
      notifyError(err);
      this.setState({ loading: false });
      throw err;
    }
    this.setState({ signFormOpen: false, loading: false, grade });
    notifySuccess('Signed activity report form.');
  };

  handleRidProfileSubmit = async (data) => {
    this.setState({ loading: true });
    const { user } = this.props.location.state;
    if (!isEqual(data, user.ridprofile)) {
      const method = user.ridprofile && user.ridprofile.pk ? 'patch' : 'post';
      const formData = { ...data, user: user.pk };
      try {
        await RidProfile[method]((user.ridprofile && user.ridprofile.pk) || formData, formData);
      } catch (err) {
        this.setState({ hasErrored: true, loading: false });
        notifyError({}, 'Could not save RID Profile.');
        throw err;
      }
    }
    this.handleSignActivityReportForm();
  };

  propsValid = () => {
    if (!this.props.location.state) {
      return { valid: false, reason: '', redirect: true };
    }
    const { survey, user, result, grade } = this.props.location.state;
    const { surveyId } = this.props.match.params;
    if (
      !survey ||
      !user ||
      !result ||
      !grade ||
      !result.final ||
      survey.id !== parseInt(surveyId) ||
      !grade.evaluated
    ) {
      return { valid: false, reason: '', redirect: true };
    }
    if (survey.publication_status === PUB_STATUS.DRAFT || moment().isAfter(moment(survey.end))) {
      return {
        valid: false,
        reason: 'Sorry, this survey has closed and is no longer accepting submissions.',
        redirect: false,
      };
    }
    return { valid: true, redirect: false, reason: '' };
  };

  async componentDidMount() {
    this.mounted = true;
    const { eventId } = this.props.match.params;
    let grade;
    try {
      grade = await Events.getMyGrade(eventId);
    } catch {
      this.setState({ hasErrored: true });
    }
    if (this.mounted) {
      this.setState({ grade, loading: false });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { valid, reason, redirect } = this.propsValid();
    const { eventId, surveyId } = this.props.match.params;

    if (redirect) {
      return <Redirect to={window.URLS['pd:take_survey'](eventId, surveyId)} />;
    }
    if (!valid) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          {reason || 'You are not authorized to access this survey.'}
        </Alert>
      );
    }

    if (this.state.hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Something went wrong. Please try again.
        </Alert>
      );
    }

    if (this.state.loading) {
      return <Loading />;
    }

    const { user } = this.props.location.state;
    const { grade, signFormOpen } = this.state;
    const signedAlert = grade.signed && (
      <Alert type="success" icon="fa-check">
        Activity report form has been signed.
      </Alert>
    );
    const viewButtons = grade.signed && (
      <div className="flex-row flex-row--space-around">
        <a
          href={window.URLS['pd:certificate_export'](eventId, user.profile.slug)}
          className="btn btn-default"
        >
          <i className="fa fa-file-pdf-o icon-space-r" />
          View Certificate
        </a>
        <Link
          to={window.URLS['accounts:pd_transcript'](user.profile.slug)}
          className="btn btn-default"
        >
          <i className="fa fa-file-text-o icon-space-r" />
          PD Transcript
        </Link>
      </div>
    );
    return (
      <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
        {signedAlert}
        {viewButtons}
        <div className="page-empty">
          <div className="page-empty__icon">
            <span className="fa fa-thumbs-o-up fa-lg" />
          </div>
          <h1 className="page-empty__title mb2">
            Thanks for participating in this evaluation, your feedback helps improve our program!
          </h1>
          {!signFormOpen && (
            <Button
              className="btn btn-lg btn-outline-primary"
              icon="fa-hand-o-right fa-lg"
              text="Eligible For CEUs?"
              onClick={() => this.setState({ signFormOpen: true })}
            />
          )}
        </div>
        {signFormOpen && (
          <RidProfileForm
            formTitle="RID Account"
            onSubmit={this.handleRidProfileSubmit}
            values={user.ridprofile || {}}
            Wrapper={Fragment}
            SubmitButton={SubmitButton}
          />
        )}
      </div>
    );
  }
}

export default CompleteSurveyPage;
