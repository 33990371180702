import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-validify';

import { FormInput } from 'components';

const defaultValues = {
  first_name: '',
  last_name: '',
  rid_account_number: '',
};

const RidProfile = (props) => {
  const { Wrapper, SubmitButton } = props;
  return (
    <Wrapper>
      <h2 className="center">{props.formTitle || 'RID Account Info (Optional)'}</h2>
      <p>
        We use this information to ensure your professional development is properly credited by RID.
        Please make sure the information listed here matches the information{' '}
        <strong>exactly</strong> as it is listed on{' '}
        <a href="https://rid.org" target="_blank">
          RID's website
        </a>
        . For instance, if your maiden name is listed on RID then please fill in your maiden name on
        this form.
      </p>
      <Form
        values={props.values || defaultValues}
        rules={{
          first_name: 'string|required',
          last_name: 'string|required',
          rid_account_number: 'numeric|integer|min:0|required',
        }}
        attributeNames={{
          first_name: 'First name',
          last_name: 'Last name',
          rid_account_number: 'RID Member Number',
        }}
      >
        <FormInput
          id="first_name"
          name="first_name"
          type="text"
          className="form-control"
          label={{ htmlFor: 'first_name', text: 'First Name' }}
          help="Exactly as it is listed on RID's site."
        />
        <FormInput
          id="last_name"
          name="last_name"
          type="text"
          className="form-control"
          label={{ htmlFor: 'last_name', text: 'Last Name' }}
          help="Exactly as it is listed on RID's site."
        />
        <FormInput
          id="rid_account_number"
          name="rid_account_number"
          type="text"
          className="form-control"
          label={{ htmlFor: 'rid_account_number', text: 'RID Member Number' }}
          help="We will never show others this information."
        />
        <div className="form-group mt2">
          <SubmitButton submit onClick={props.onSubmit} submitButtonText={props.submitButtonText} />{' '}
          {props.onSkip ? (
            <button type="button" className="btn btn-info" onClick={props.onSkip}>
              Skip
            </button>
          ) : null}
        </div>
      </Form>
    </Wrapper>
  );
};

const DefaultWrapper = (props) => (
  <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6">{props.children}</div>
);

DefaultWrapper.propTypes = {
  children: PropTypes.node,
};

const DefaultSubmitButton = (props) => (
  <button type="button" className="btn btn-primary" onClick={(values) => props.onClick(values)}>
    {props.submitButtonText || 'Submit'}
  </button>
);

DefaultSubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
};

RidProfile.defaultProps = {
  Wrapper: DefaultWrapper,
  SubmitButton: DefaultSubmitButton,
  values: {
    first_name: '',
    last_name: '',
    rid_account_number: '',
  },
};

RidProfile.propTypes = {
  Wrapper: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
    PropTypes.symbol,
  ]),
  SubmitButton: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  formTitle: PropTypes.string,
  submitButtonText: PropTypes.string,
  onSkip: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
};

export default RidProfile;
